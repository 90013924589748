import { useCallback, useEffect, useMemo, useState } from "react";
import { useDataProvider } from "react-admin";
import { ResourceEditor } from "./ResourceEditor/ResourceEditor";
import { SelectCreateScreen } from "./ResourceEditor/SelectCreateScreen";

type ActivitiesManagerProps = {
  id: string;
};

export const ActivitiesManager = ({ id }: ActivitiesManagerProps) => {
  const dataProvider = useDataProvider();
  const [enabledResources, setEnabledResources] = useState([]);

  const fetchPropertyResources = useCallback(async () => {
    const { data } = await dataProvider.getPropertyActivities({ id: id });
    return data;
  }, [dataProvider, id]);

  const fetchEnabledResources = useCallback(async () => {
    const { data } = await dataProvider.getChildrenActivities();
    setEnabledResources(data);
  }, [dataProvider]);

  const updateResources = useCallback(
    async (resources: any[]) =>
      dataProvider.updateProperyActivities(id, {
        activities: resources.map((a: any) => a.id),
      }),
    [dataProvider, id],
  );

  const handleMoveItems = useCallback(
    (resources: any[]) => updateResources(resources),
    [updateResources],
  );

  const handleRemoveItem = useCallback(
    (resources: any[], removedResource: any) =>
      updateResources(
        resources.filter((item: any) => item.id !== removedResource.id),
      ),
    [updateResources],
  );

  const CreateScreen = useMemo(
    () => (props: any) => (
      <SelectCreateScreen
        {...props}
        label="Activity"
        onSave={(resources, selectedResource) =>
          updateResources([...resources, selectedResource])
        }
        getSelectOptions={(resources) =>
          enabledResources.filter(
            (type: any) => !resources.some((res) => res.id === type.id),
          )
        }
      />
    ),
    [enabledResources, updateResources],
  );

  useEffect(() => {
    fetchEnabledResources();
  }, []);

  const RenderName = (resource: any) => {
    return (
      <div className="resource-with-icon">
        {resource.icon && <img src={resource.icon.uri} alt="activity icon" width={20} height={20} />}
        <span>{resource.name}</span>
      </div>
    )
  }

  return (
    <ResourceEditor
      hasOrder
      hasBorder
      resourceId="activities"
      heading="Activities"
      renderName={RenderName}
      createNewLabel="Add New Activity"
      updatingOrderMsg="Updating activities order..."
      updatedOrderMsg="Activities order updated."
      fetchResourceItems={fetchPropertyResources}
      CreateScreen={CreateScreen}
      onUpdateResourceItems={handleMoveItems}
      onRemoveResourceItem={handleRemoveItem}
    />
  );
};
